@media only screen and (min-width: 768px) {
    
    .useCases-bg {
      background: url('../../image_bg/useCases.jpg');
      background-size: cover;
      background-position: 0% 45%;
      background-attachment: fixed;
      padding: 0%;
    }

    .case-bg{
      width: 100%;  
      padding:5% 15% 5% 15%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
    }

    .bg-orange{
      background-color: rgba(255, 120, 0, 0.8);
    }
    .bg-bleu{
      background-color: rgba(45, 61, 129, 0.8);
    }

    .useCaseContentWrapper{
      width: 100%;  
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap:wrap;
      background-color: rgba(250,250,250,0.95);
      padding:2% 2% 2% 2%;
      border-radius: 10px 10px 10px 10px;
      filter: drop-shadow(4px 4px 4px rgba(0,0,0,0.4));
      
    }

    .useCaseImgHolder{
      width: 20%;  
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
    }

    .useCaseTxtHolder{
      width: 80%;  
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: nowrap;
      padding: 0% 0% 0% 5%;
    }

    .useCaseImg{
      width: 100%;  
      height: 100%;
      object-fit: contain;
    }

    .useCasePadding{
      padding: 0% 0% 3% 0%;
    }

    

   

    







      
      
     
      
}
@media only screen and (max-width: 768px) {
  .useCases-bg {
    background: 
    linear-gradient(
      rgba(255, 120, 0, 0.8), 
      rgba(255, 120, 0, 0.8)),
      url('../../image_bg/useCases.jpg');
    background-size: cover;
    background-position: 0% 45%;
    background-attachment: fixed;
    padding: 0%;
  }

  .case-bg{
    width: 100%;  
    padding:0% ;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .bg-orange{
    background-color: rgba(255, 255, 255, 0.5);
  }
  .bg-bleu{
    background-color: rgba(255, 255, 255, 0.8);
  }

  .useCaseContentWrapper{
    width: 100%;  
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap:nowrap;
    padding: 5% 0% 0% 5%;
  }

  .useCaseImgHolder{
    width: 60%;  
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0% 0% 0% 0%;
  }

  .useCaseTxtHolder{
    width: 100%;  
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: nowrap;
    padding: 5% 2% 0% 0%;
  }

  .useCaseImg{
    width: 100%;  
    height: 100%;
    object-fit: contain;
    scale: 30%;
  }

  .useCasePadding{
    padding: 0% 0% 3% 0%;
  }


    
}