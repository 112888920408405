


@media only screen and (min-width: 768px) {

    .headerButton{
        height: 100%;
        min-width: 10%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        border-top:0;
        border-left:0;
        border-right:0;
        border-Bottom: 8px solid rgba(255,255,255,0);
        outline: none !important;
        color: rgba(255,255,255,1);
        font-family:Roboto;
        font-size:14px;
        font-weight: bold;
        text-transform: uppercase;
        cursor: pointer;
        margin-right: 20px;
    }
    .headerButton:hover{
        background-color:rgba(255,255,255,0.2);
        border-Bottom: 8px solid rgba(255, 120, 0, 0.9);
    }

    .headerButton2{
        height: 100%;
        min-width: 10%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        border-top:0;
        border-left:0;
        border-right:0;
        border-Bottom: 8px solid rgba(255,255,255,0);
        outline: none !important;
        color: rgba(21, 43, 141,1);
        font-family:Roboto;
        font-size:14px;
        font-weight: bold;
        text-transform: uppercase;
        cursor: pointer;
        margin-right: 20px;
    }
    .headerButton2:hover{
        background-color:rgba(21, 43, 141,0.2);
        border-Bottom: 8px solid rgba(255, 120, 0, 0.9);
    }

    .helpdesk{
        color: rgba(255,255,255,1);
        text-decoration: none;
        font-size:14px;
    }

    .helpdesk:visited{
        color: rgba(255,255,255,1);
        text-decoration: none;
        font-size:14px;
    }

    .helpdesk2{
        color: rgba(21, 43, 141,1);
        text-decoration: none;
        font-size:14px;
    }

    .helpdesk2:visited{
        color: rgba(21, 43, 141,1);
        text-decoration: none;
        font-size:14px;
    }
}

@media only screen and (max-width: 768px) {
    .headerButton{
        height: 100%;
        width: 25%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        /* padding-left: 1%; */
        border-top:0;
        border-left:0;
        border-right:0;
        border-Bottom: 2px solid rgba(255,255,255,0);
        outline: none !important;
        color: rgba(255,255,255,1);
        font-family:'FuturaStd-CondensedBold';
        font-size:12px;
        font-weight: bold;
        text-transform: uppercase;
        cursor: pointer;
    }
    .headerButton:hover{
        background-color:rgba(255,255,255,0.2);
        border-Bottom: 2px solid rgba(255, 120, 0, 0.9);
    }
    .helpdesk{
        color: rgba(255,255,255,1);
        text-decoration: none;
        font-size:14px;
    }

    .helpdesk:visited{
        color: rgba(255,255,255,1);
        text-decoration: none;
        font-size:14px;
    }
    .hb-link {
        height: 50px;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        background-color: transparent;
        padding-left: 10%;
        border-top:0;
        border-left:0;
        border-right:0;
        border-Bottom: 8px solid rgba(255,255,255,0);
        outline: none !important;
        font-family:Roboto;
        font-size:14px;
        font-weight: bold;
        text-transform: uppercase;
        cursor: pointer;
      }
      
      .hb-link:hover {
        background-color: rgba(250, 250, 250, 0.2);
      }
      
      
}

