@media only screen and (min-width: 768px) {

    .portfolio-bg {
        padding: 0px;
    }

    .portfolioWrapper{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        flex-wrap: nowrap;
    }

    .portfolioHeaderInput{
        width: 100%;
        height: 5%;
        display: flex;
        justify-content: center;
        align-items: center;
        /* border-top: 1px solid rgba(177,177,155,0.8); */
        /* border-top: 1px solid rgba(21, 43, 141, 0.9);
        border-bottom: 1px solid rgba(21, 43, 141, 0.9); */
        padding: 10px 5% 10px 10%;
        background-color: rgba(255, 120, 0, 1);
        /* background-color:rgba(21, 43, 141, 0.1); */
    }

    
    .mapWrapper{
        width: 100%;
        height: 95%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        /* border-top: 2px solid rgba(177,177,155,0.8); */
        
    }

    /* postcode component------------------------ */
    .pc-input-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        flex-wrap: nowrap;
        font-size: 0.8em;
        color:rgba(21, 43, 141, 1);
        /* color:rgba(254, 254, 254, 0.9); */
        
    }

    .pc-inputBoxTxt {
        width: 8%;
        height: 100%;
        padding:1% 0% 1% 2%;
        border-radius: 25px;
        background-color: rgb(230,230,230);
        border: 1px solid rgb(150,150,150);
        margin:0px 20px 0px 10px;
        outline: none;
    }
    .pc-inputSlider{
        width: 25%;
        margin: 0px 10px 0px 20px;
    }

    .pc-distance{
        width: 10%;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 0.9em;
        /* border-radius: 8px; */
        font-weight: 800;
        /* color: rgba(21, 43, 141, 0.8); */
        /* background-color:  rgba(21, 43, 141, 0.3); */
        margin:0px 5% 0px 0px;
        padding: 1%;
    }

    .pc-inputBtn {
        width: auto;
        height:100%;
        background-color:rgba(21, 43, 141, 1);
        color: white;
        display: flex;
        justify-content: center;
        align-items:center;
        cursor: pointer;
        outline: none;
        border:1px solid rgba(21, 43, 141, 1);
        border-radius: 4px;
        padding: 1% 2% 1% 2%;
        filter: drop-shadow(1px 1px 1px rgba(0,0,0,0.5));
    }

    .pc-inputBtn:disabled  {
        background-color:rgba(21, 43, 141, 0.1);
        color: rgba(21, 43, 141, 0.2);
        border:1px solid rgba(21, 43, 141, 0.1);
       
    }
    .pc-message{
        width: auto;
        height:100%;
        display: flex;
        justify-content: flex-start;
        align-items:center;
        font-weight: 700;
        padding: 10px;
        background-color:rgba(255, 120, 0, 0.3);
        margin:0px 0% 0px 20px;
        padding: 1% 2% 1% 2%;
    }
    
    
    /* layout infowindow gogle maps */
    .gm-style-iw{
        max-width: 300px !important; 
        overflow: hidden !important;
    } 

    .loaderHolder{
        width: 40px;
        height: 40px;
        padding: 4px;
        margin-right: 10px;
    }
  
}
@media only screen and (max-width: 768px) {
    .portfolio-bg {
        padding: 0px;
        padding: 0% !important;
    }

    .portfolioWrapper{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        flex-wrap: nowrap;
    }

    .portfolioHeaderInput{
        width: 100%;
        height: 20%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid rgba(255, 120, 0, 1); 
        padding: 10px 10px 10px 10px; 
        background-color: rgba(255, 120, 0, 0.3);
    }

    
    .mapWrapper{
        width: 100%;
        height: 80%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        /* border-top: 2px solid rgba(177,177,155,0.8); */
        
    }

    /* postcode component------------------------ */
    .pc-input-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: row;
        flex-wrap: wrap;
        font-size: 0.7em;
        color:rgba(21, 43, 141, 1);
        
    }
    .pc-input-rows{
        width: 100%;
        height: 33%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: row;
        flex-wrap: wrap;
        font-family: 'FuturaStd-Book';
        font-size: 0.9em;
        color:rgba(21, 43, 141, 1);
    }

    .pc-inputBoxTxt {
        width: 40%;
        height:100%;
        padding:1% 0% 1% 2%;
        font-size: 1.2em;
        font-weight: 500;
        border-radius: 6px;
        background-color: rgb(230,230,230);
        border: 1px solid rgb(150,150,150);
        margin:0px 10px 0px 10px;
        outline: none;
    }

    .pc-sliderHolder{
        width: 100%;
        height: 10%;
        position:absolute;
        left:0px;
        bottom: 5%;  /* zelde waarde als footor height for mobile */
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        flex-wrap: nowrap;
        font-size: 0.8em;
        color:rgba(21, 43, 141, 1);
        z-index: 1000;
        background-color: rgba(255, 120, 0, 0.8);
        padding:  8px 10px 0px 10px;
    }
    .pc-inputSlider{
        width: 75%;
        margin-left: 10px;
    }

    .pc-distance{
        font-size: 0.9em;
        font-weight: 700;
        color: rgba(21, 43, 141, 1);
        margin:4px 0px 4px 0px;
        padding: 1%;
    }

    .pc-inputBtn {
        width: 20%;
        height:100%;
        background-color:rgba(21, 43, 141, 8);
        color: white;
        display: flex;
        justify-content: center;
        align-items:center;
        flex-wrap: wrap;
        cursor: pointer;
        outline: none;
        border: none;
        /* border:1px solid rgba(21, 43, 141, 0); */
        border-radius: 4px;
        /* padding: 1% 2% 1% 2%; */
        /* filter: drop-shadow(1px 1px 1px rgba(0,0,0,0.5)); */
    }

    .pc-inputBtn:disabled  {
        background-color:rgba(21, 43, 141, 0.1);
        color: rgba(21, 43, 141, 0.2)
       
    }
    .pc-message{
        width: 79%;
        height:100%;
        display: flex;
        justify-content: flex-start;
        align-items:center;
        font-weight: 700;
        padding: 10px;
        background-color:rgba(255, 120, 0, 0.2);
        margin:0px 1% 0px 0px;
        padding: 1% 2% 1% 2%;
    }
    

    /* layout infowindow gogle maps */
    .gm-style-iw{
        max-width: 300px !important; 
        overflow: hidden !important;
    } 
    
    .loaderHolder{
        width: 40px;
        height: 40px;
        padding: 4px;
        margin-right: 10px;
    }
    
}