@media only screen and (min-width: 768px) {
    .overons-bg {
        padding: 0%;
        overflow-y: hidden;
      }

      .overOns-navi{
        width: 100%;
        height: 5%;  
        padding:0% 0% 0% 5%;
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
        flex-direction: row;
        flex-wrap: wrap;
        background-color: rgba(45, 61, 129, 0.8);
      }
      .overOns-mainContainer{
        width: 100%;
        height: 95%;
        margin: 0px;
        overflow-y: auto;
        overflow-x: hidden;
        display: flex;
        justify-content: center;
        align-items: flex-start;
      }

      .overOns-blocks-bg{
        width: 100%;  
        padding:5% 1% 5% 15%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
      }

      .overOns-blocks-bg-1{
        width: 100%;  
        padding:1% 1% 1% 1%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
      }

      .oo-bg-filosofie{
        background: linear-gradient(  rgba(255, 120, 0, 0.6),   rgba(255, 120, 0, 0.1)),  url('../../image_bg/teaser1.jpg');
        background-size: cover;
        background-position: 0% 45%;
        background-attachment: fixed;
        background-repeat: no-repeat;
      }

      .oo-bg-ooh{
        background: linear-gradient(  rgba(255, 120, 0, 0.8),  rgba(255, 120, 0, 0.1)),  url('../../image_bg/inspiratie.jpg');
        background-size: cover;
        background-position: 0% 45%;
        background-attachment: fixed;
        background-repeat: no-repeat;
      }

      .oo-bg-3{
        background: linear-gradient( rgba(255, 120, 0, 0.6), rgba(255, 120, 0, 0.1)), url('../../image_bg/filosofie.jpg');
        background-size: cover;
        background-position: 0% 45%;
        background-attachment: fixed;
        background-repeat: no-repeat;
      }

      .oo-bg-4{
        background: linear-gradient( rgba(255, 120, 0, 0.6),  rgba(255, 120, 0, 0.6)),  url('../../image_bg/useCases.jpg');
        background-size: cover;
        background-position: 0% 45%;
        background-attachment: fixed;
        background-repeat: no-repeat;
      }

      .overOns-content-wrap{
        width: 60%;
        max-width: 60%;  
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap:nowrap;
        padding:5% 3% 5% 3%;
        background-color: rgba(21, 43, 141, 0.85);  
      }

      .scroll-y{
        overflow-y: auto;
      }

      .overOns-content-bottom{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap:wrap;
        margin-bottom: 10%;
      }


      .overOns-content-wrap-top{
        width: 100%;  
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        flex-wrap:wrap;
        background-color: rgba(250,250,250,0.95);
        padding:2% 2% 2% 2%;
        border-radius: 10px 10px 10px 10px;
        filter: drop-shadow(4px 4px 4px rgba(0,0,0,0.4));
      }

      .overOns-content-wrap-1{
        width: 100%;  
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap:wrap;
        background-color: rgba(250,250,250,0.95);
        /* padding:2% 2% 2% 2%; */
        margin: 2% 0% 2% 0%;
        border-radius: 10px 10px 10px 10px;
        filter: drop-shadow(4px 4px 4px rgba(0,0,0,0.4));
        
      }

      .overOns-nav {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
      }
  
      .overOns-text-holder{
        width: 80%;  
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: nowrap;
        padding: 0% 0% 0% 5%;
      }
      
      .navigation-OO {
        font-size: 0.8em;
        font-weight: 400;
        text-transform: uppercase;
        text-decoration: none;
      }
      
      .navigation-OO:hover {
        color: white;
      }

      .button-oo {
        background-color: rgba(15, 31, 100, 0.8);
        padding: 10px;
        border-radius: 5px;
      }

      .cont-top-vest-1 {
        width: 100%;
        height: 20%;
        display: flex;
        flex-direction: column;
      }
      
      .adresbox-OO {
        display: flex;
        flex-direction: column;
        padding: 0% 0% 0% 0%;
      }
}
@media only screen and (max-width: 768px) {

  .overons-bg {
    padding: 0% !important;
    overflow-y: hidden;
    background: linear-gradient( rgba(21, 43, 141, 1) 40%, rgba(21, 43, 141, 0.7)), url('../../image_bg/home3.jpg');
    background-size: cover;
    background-position: 0% 45%;
    background-attachment: fixed;
    background-repeat: no-repeat;
  }

  .overOns-navi{
    width: 100%;
    height: 5%;  
    padding:0% 0% 0% 0%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    background-color: rgba(45, 61, 129, 0.8);
  }
  .overOns-mainContainer{
    width: 100%;
    height: 95%;
    margin: 0px;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  .overOns-blocks-bg{
    width: 100%;  
    padding:5% 1% 5% 15%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .overOns-blocks-bg-1{
    width: 100%;  
    padding:1% 1% 1% 1%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
  }

  .overOns-content-wrap{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap:nowrap;
    padding:10% 5% 10% 5%;
    /* background-color: rgba(21, 43, 141, 0.85); */
    background-color: transparent;    
  }

  .scroll-y{
    overflow-y: auto;
  }

  .overOns-content-bottom{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap:wrap;
    margin-bottom: 10%;
  }


  .overOns-content-wrap-top{
    width: 100%;  
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    flex-wrap:wrap;
    background-color: rgba(250,250,250,0.95);
    padding:2% 2% 2% 2%;
    border-radius: 10px 10px 10px 10px;
    filter: drop-shadow(4px 4px 4px rgba(0,0,0,0.4));
  }

  .overOns-content-wrap-1{
    width: 100%;  
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap:wrap;
    background-color: rgba(250,250,250,0.95);
    /* padding:2% 2% 2% 2%; */
    margin: 2% 0% 2% 0%;
    border-radius: 10px 10px 10px 10px;
    filter: drop-shadow(4px 4px 4px rgba(0,0,0,0.4));
    
  }

  .overOns-nav {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  .overOns-text-holder{
    width: 80%;  
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: nowrap;
    padding: 0% 0% 0% 5%;
  }
  
  .navigation-OO {
    font-size: 0.8em;
    font-weight: 400;
    text-transform: uppercase;
    text-decoration: none;
  }
  
  .navigation-OO:hover {
    color: rgba(255, 120, 0, 1)
  }

  .cont-top-vest {
    width: 100%;
    height: 20%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  
  .adresbox-OO {
    display: flex;
    flex-direction: column;
    padding: 0% 0% 0% 0%;
  } 
}