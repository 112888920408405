

/* Position and sizing of burger button */
.bm-burger-button {
    position: fixed;
    width: 30px;
    height: 25px;
    left: 10px;
    top: 6px;
}
.bm-burger-button:focus{
    outline:0;
    border: none;
}
  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: rgba(255, 120, 0, 1);
  }
  
  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    /* background: rgba(255, 120, 0, 1); */
  }
  
  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }
  
  /* Color/shape of close button cross */
  .bm-cross {
    background: rgb(255, 120, 0);
    opacity: 1
  }
  
  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
  .bm-menu-wrap {
    position: fixed;
    top:0%; 
    
  }
  
  /* General sidebar styles */
  .bm-menu {
    background: rgba(21, 43, 141, 0.95);
    
  }
  
  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: rgba(21, 43, 141, 0.95);
  }
  
  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
    /* padding: 0.1em; */
    
  }
  
  /* Individual item */
  .bm-item {
    height:40px;
    padding: 0px 0px 0px 0px;
    background-color: transparent;
    margin-bottom: 10px !important; 
    border: none !important;
  }
  
  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0);
  }

  

.bm-button:focus {
    outline:0;
    border: none;
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slideIn {
  0% {
    transform: translateX(-2%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes shrink {
  0% {
    width: 95%;
  }
  100% {
    width: 90%;
  }
}

    




