 .App {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    flex-wrap: nowrap;
    overflow:hidden
  }


@media only screen and (min-width: 768px) {
  .main-container {
    width: 100%;
    height: 90%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0px 0px 0px 0px;
    margin: 0px 0px 0px 0px;
  }
  .container-home{
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 5% 10% 3% 10%;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .container{
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0%;
    overflow-y: auto;
    overflow-x: hidden;
  }



  .footer{
    width: 100%;
    height: 5%;
    background-color: rgba(21, 43, 141, 1);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    border-top: 1px solid rgba(177,177,155,0.3);
    /* padding-top: 10px; */
}

  .contentWrapper{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .textsizing-standard {
    font-size: 1em;
    font-family: 'FuturaStd-Medium';
  }

  .h1 {
    font-size: 2em;
    font-family: 'FuturaStd-Bold';
  }

  .h1-home {
    font-size: 1.7em;
    font-family: 'FuturaStd-Bold';
  }

  .titel{
    font-family: 'FuturaStd-Book';
    font-size: 1.5em;
    text-transform: uppercase;
  }
  .plat{
    font-size: 1em;
    line-height: 1.5;
    font-family: 'FuturaStd-Medium';
  }

  .italic{
    font-style:italic;
  }

}

@media only screen and (max-width: 768px) {

  .App {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    flex-wrap: nowrap;
    overflow:hidden
  }

  .main-container {
    width: 100%;
    height: 90%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    flex-wrap: nowrap;
    padding: 0px 0px 0px 0px;
    margin: 0px;
  }


  .container-home{
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 10% 3% 3% 3%;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .container{
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 10% 3% 3% 3%;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .contentWrapper{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .footer{
    width: 100%;
    height: 5%;
    background-color: rgba(21, 43, 141, 1);
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    border-top: 1px solid rgba(177,177,155,0.3);
    padding-top: 10px;
}


.textsizing-standard {
  font-size: 1em;
  font-family: 'FuturaStd-Medium';
}

.h1 {
  font-size: 1.5em;
  font-family: 'FuturaStd-Bold';
}

.titel{
  font-family: 'FuturaStd-Book';
  font-size: 1.1em;
  text-transform: uppercase;
}
.plat{
  font-size: 0.9em;
  line-height: 1.5;
  font-family: 'FuturaStd-Medium';
}

.italic{
  font-style:italic;
}

}

.blauw {
  color:rgba(21, 43, 141, 1)
}

.oranje {
  color:rgba(255, 120, 0, 1)
}
.wit{
  color:rgba(254, 245,245, 1)
}

.link-color-white:link, .link-color-white:visited {
  font-family: 'FuturaStd-Light';
  color: white;
  text-decoration: none;
}

.link-color-white:hover {
  color:rgba(255, 120, 0, 1);
}

.link-color-orange:link, .link-color-orange:visited {
  color: rgba(255, 120, 0, 1);
  text-decoration: none;
}

.link-color-orange:hover {
  color: rgba(255, 255, 255, 1);
}

.flstart{
  justify-content: flex-start;
  align-items: flex-start;
}
