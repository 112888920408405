@media only screen and (min-width: 768px) {
    .vs-root{
        width: 100%;
        display:flex;
        flex-direction: column;
        justify-content:flex-start;
        margin-bottom: 10%;
        background-color: rgba(254,254,254,0.2);
        padding: 2% 2% 2% 2%;
        border-radius: 6px;
        box-shadow: 2px 2px 2px rgba(0,0,0,0.5);
    }

    .vs-containervestiging{
        display:flex;
        flex-direction:row;
        width:100%;
    }
    .vs-employees-wrapper{
        width:100%;
        display:flex;
        flex-direction:row;
        flex-wrap: wrap;
        
    }

    .vs-employeeContainer {
        width:80px; 
        padding: 2% 2% 2% 2%;
        display:flex;
        flex-direction: column;
        justify-content:center;
        font-size:0.8em;
        
    }

    .vs-employeeIcon {
        width: 100%;
        padding: 12% 12% 8% 12%;
        margin-bottom: 1%;
        filter: drop-shadow(2px 2px 4px rgba(0,0,0,0.4));
    }

    .vs-infoContainer {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .vs-top-company-wrapper{
        width: 100%;
        display:flex;
        flex-direction: row;
        justify-content:flex-start;
        flex-wrap: wrap;
    }
    .vs-pand{
        width: 30%;
        margin: 0px 20px 20px 0px;
        border-radius: 10px;
        box-shadow: 2px 2px 2px rgba(0,0,0,0.5);
    }
}
@media only screen and (max-width: 768px) {
    .vs-root{
        width: 100%;
        display:flex;
        flex-direction: column;
        justify-content:flex-start;
        margin-bottom: 10%;
        background-color: rgba(254,254,254,0.2);
        padding: 2% 2% 2% 2%;
        border-radius: 6px;
        box-shadow: 2px 2px 2px rgba(0,0,0,0.5);
    }

    .vs-containervestiging{
        display:flex;
        flex-direction:row;
        width:100%;
    }
    .vs-employees-wrapper{
        width:100%;
        display:flex;
        flex-direction:row;
        flex-wrap: wrap;
        
    }

    .vs-employeeContainer {
        width:60px; 
        padding: 2% 2% 2% 2%;
        display:flex;
        flex-direction: column;
        justify-content:center;
        font-size:0.8em;
        
    }

    .vs-employeeIcon {
        width: 100%;
        padding: 12% 12% 8% 12%;
        margin-bottom: 1%;
        filter: drop-shadow(2px 2px 4px rgba(0,0,0,0.4));
    }

    .vs-infoContainer {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .vs-top-company-wrapper{
        width: 100%;
        display:flex;
        flex-direction: row;
        justify-content:flex-start;
        flex-wrap: wrap;
    }
    .vs-pand{
        width: 100%;
        margin: 0px 0px 20px 0px;
        border-radius: 6px;
        /* box-shadow: 2px 2px 2px rgba(0,0,0,0.5); */
    }
}