@import "~react-image-gallery/styles/css/image-gallery.css";

@media only screen and (min-width: 768px) {
    .inspiratie-bg {
        background: 
        linear-gradient(
          rgba(255, 120, 0, 0.7), 
          rgba(255, 120, 0, 0.7)),
        url('../../image_bg/voetbal.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 0% 45%;
        background-attachment: fixed;
        padding: 0%;
      }

    .insp-container {
      width: 100%;  
      padding:5% 15% 5% 15%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
    }

    .insp-contentWindow{
      width: 100%;
      height: 100%;
      display:flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      flex-wrap: nowrap;
      filter: drop-shadow(4px 4px 4px rgba(0,0,0,0.4));
    }

    .inspContent{
      width: 100%;  
      /* height: 100%; */
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap:wrap;
      background-color: rgba(21, 43, 141, 0.8);
      padding:2% 5% 2% 5%;
      border-radius:  5px 5px 5px 5px;
    }

    .insp-titel-bew {
      width: 100%;
      display: flex;
      text-transform: uppercase;
      justify-content: center;
      background-color: rgba(255, 120, 0, 0.7);
      padding-top: 1%;
      margin-top: 2%;
      border-radius: 5px 5px 0px 0px;
    }

    .insp-titel-stil {
      width: 100%;
      display: flex;
      text-transform: uppercase;
      justify-content: center;
      background-color: rgba(21, 43, 141, 0.8);
      padding-top: 1%;
      margin-top: 2%;
      border-radius: 5px 5px 0px 0px;
    }

    .insp-bg-oranje {
      width: 100%;
      background-color: rgba(255, 120, 0, 0.7);
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      border-radius: 0px 0px 5px 5px;
    }

    .insp-bg-blauw {
      width: 100%;
      background-color: rgba(21, 43, 141, 0.8);
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      border-radius: 0px 0px 5px 5px;
    }
    .inspVideoWrapper{
      width: 100%;  
      /* height: 100%; */
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      flex-wrap:wrap;
      
    }
    .insp-video-item{
      height:auto;
      max-height: 320px;
      max-width: 320px;
      margin: 20px;
      filter: drop-shadow(4px 4px 4px rgba(0,0,0,0.6));
    }

    .inspStillWrapper{
      width: 100%;  
      /* height: 100%; */
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      flex-wrap:wrap;
      padding:0%;
    }

    .insp-still-item{
        width: 220px;
        height: auto;
        border-radius: 5px;
        margin: 20px;
        filter: drop-shadow(4px 4px 4px rgba(0,0,0,0.6));
    }
}

@media only screen and (max-width: 768px) {
  .inspiratie-bg {
    background-color: rgba(21, 43, 141, 0.8);
    padding: 0%;
  }

.insp-contentWindow{
  width: 100%;
  height: 100%;
  padding:1% 5% 1% 5%;
  display:flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
}

.inspContent{
  width: 100%;  
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap:wrap;
  padding:5% 1% 5% 1%;
}
.insp-titel-bew {
  width: 100%;
  display: flex;
  text-transform: uppercase;
  justify-content: center;
  background-color: rgba(255, 120, 0, 0.7);
  padding-top: 2%;
  margin-top: 2%;
  border-radius: 5px 5px 0px 0px;
}

.insp-titel-stil {
  width: 100%;
  display: flex;
  text-transform: uppercase;
  justify-content: center;
  background-color: rgba(21, 43, 141, 0.8);
  padding-top: 2%;
  margin-top: 2%;
  border-radius: 5px 5px 0px 0px;
}

.insp-bg-oranje {
  width: 100%;
  background-color: rgba(255, 120, 0, 0.7);
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  border-radius: 0px 0px 5px 5px;
}

.insp-bg-blauw {
  width: 100%;
  background-color: rgba(21, 43, 141, 0.8);
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  border-radius: 0px 0px 5px 5px;
}
.inspVideoWrapper{
  width: 100%;  
  /* height: 100%; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  flex-wrap:wrap;
  padding: 5%;
  
}
.insp-video-item{
    height:auto;
    max-height: 320px;
    max-width: 320px;
    margin: 20px;
    filter: drop-shadow(4px 4px 4px rgba(0,0,0,0.6));
}

.inspStillWrapper{
  width: 100%;  
  /* height: 100%; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap:wrap;
  padding:0%;
}

.insp-still-item{
    width: 240px;
    height: auto;
    background-color: rgb(0,0,0);
    border-radius: 5px;
    margin: 20px;
    filter: drop-shadow(4px 4px 4px rgba(0,0,0,0.6));
}
    
}