
@font-face {
    font-family: 'FuturaStd-Medium';
    src: url('./FuturaStd-Medium.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'FuturaStd-Bold';
    src: url('./FuturaStd-Bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'FuturaStd-Book';
    src: url('./FuturaStd-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'FuturaStd-Bold';
    src: url('./FuturaStd-Bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'FuturaStd-Light';
    src: url('./FuturaStd-Light.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'FuturaStd-CondensedBold';
    src: url('./FuturaStd-CondensedBold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

