


@media only screen and (min-width: 768px) {

    .headerButton{
        height: 100%;
        min-width: 10%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        border-top:0;
        border-left:0;
        border-right:0;
        border-Bottom: 8px solid rgba(255,255,255,0);
        outline: none !important;
        color: rgba(255,255,255,1);
        font-family:Roboto;
        font-size:14px;
        font-weight: bold;
        text-transform: uppercase;
        cursor: pointer;
        margin-right: 20px;
    }
    .headerButton:hover{
        background-color:rgba(255,255,255,0.2);
        border-Bottom: 8px solid rgba(255, 120, 0, 0.9);
    }

    .headerButton2{
        height: 100%;
        min-width: 10%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        border-top:0;
        border-left:0;
        border-right:0;
        border-Bottom: 8px solid rgba(255,255,255,0);
        outline: none !important;
        color: rgba(21, 43, 141,1);
        font-family:Roboto;
        font-size:14px;
        font-weight: bold;
        text-transform: uppercase;
        cursor: pointer;
        margin-right: 20px;
    }
    .headerButton2:hover{
        background-color:rgba(21, 43, 141,0.2);
        border-Bottom: 8px solid rgba(255, 120, 0, 0.9);
    }

    .helpdesk{
        color: rgba(255,255,255,1);
        text-decoration: none;
        font-size:14px;
    }

    .helpdesk:visited{
        color: rgba(255,255,255,1);
        text-decoration: none;
        font-size:14px;
    }

    .helpdesk2{
        color: rgba(21, 43, 141,1);
        text-decoration: none;
        font-size:14px;
    }

    .helpdesk2:visited{
        color: rgba(21, 43, 141,1);
        text-decoration: none;
        font-size:14px;
    }
}

@media only screen and (max-width: 768px) {
    .headerButton{
        height: 100%;
        width: 25%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        /* padding-left: 1%; */
        border-top:0;
        border-left:0;
        border-right:0;
        border-Bottom: 2px solid rgba(255,255,255,0);
        outline: none !important;
        color: rgba(255,255,255,1);
        font-family:'FuturaStd-CondensedBold';
        font-size:12px;
        font-weight: bold;
        text-transform: uppercase;
        cursor: pointer;
    }
    .headerButton:hover{
        background-color:rgba(255,255,255,0.2);
        border-Bottom: 2px solid rgba(255, 120, 0, 0.9);
    }
    .helpdesk{
        color: rgba(255,255,255,1);
        text-decoration: none;
        font-size:14px;
    }

    .helpdesk:visited{
        color: rgba(255,255,255,1);
        text-decoration: none;
        font-size:14px;
    }
    .hb-link {
        height: 50px;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        background-color: transparent;
        padding-left: 10%;
        border-top:0;
        border-left:0;
        border-right:0;
        border-Bottom: 8px solid rgba(255,255,255,0);
        outline: none !important;
        font-family:Roboto;
        font-size:14px;
        font-weight: bold;
        text-transform: uppercase;
        cursor: pointer;
      }
      
      .hb-link:hover {
        background-color: rgba(250, 250, 250, 0.2);
      }
      
      
}




/* Position and sizing of burger button */
.bm-burger-button {
    position: fixed;
    width: 30px;
    height: 25px;
    left: 10px;
    top: 6px;
}
.bm-burger-button:focus{
    outline:0;
    border: none;
}
  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: rgba(255, 120, 0, 1);
  }
  
  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    /* background: rgba(255, 120, 0, 1); */
  }
  
  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }
  
  /* Color/shape of close button cross */
  .bm-cross {
    background: rgb(255, 120, 0);
    opacity: 1
  }
  
  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
  .bm-menu-wrap {
    position: fixed;
    top:0%; 
    
  }
  
  /* General sidebar styles */
  .bm-menu {
    background: rgba(21, 43, 141, 0.95);
    
  }
  
  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: rgba(21, 43, 141, 0.95);
  }
  
  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
    /* padding: 0.1em; */
    
  }
  
  /* Individual item */
  .bm-item {
    height:40px;
    padding: 0px 0px 0px 0px;
    background-color: transparent;
    margin-bottom: 10px !important; 
    border: none !important;
  }
  
  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0);
  }

  

.bm-button:focus {
    outline:0;
    border: none;
}

@-webkit-keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes slideIn {
  0% {
    -webkit-transform: translateX(-2%);
            transform: translateX(-2%);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@keyframes slideIn {
  0% {
    -webkit-transform: translateX(-2%);
            transform: translateX(-2%);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@-webkit-keyframes shrink {
  0% {
    width: 95%;
  }
  100% {
    width: 90%;
  }
}

@keyframes shrink {
  0% {
    width: 95%;
  }
  100% {
    width: 90%;
  }
}

    





@media only screen and (min-width: 768px) {
    /* social media */
    .smWrapper{
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .smButton{
        height: 30px;
        width: 30px;
        margin-left: 10px;
        outline: none !important;
        border:none;
        cursor: pointer;
        box-Shadow: 2px 2px 4px rgba(0,0,0,0.3);
    }
    .smFB{
        background: url(/static/media/facebook.20566f7b.svg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
    .smIn{
        background: url(/static/media/instagram.7ee89ee5.svg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        margin-right: 15px;
    }
    .smLi{
        background: url(/static/media/linkedin.cffcb16f.svg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }


}
@media only screen and (max-width: 768px) {
    /* social media */
    .smWrapper{
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20% 0% 0% 0%;
    }

    .smButton{
        height: 40px;
        width: 40px;
        margin-left: 20px;
        outline: none !important;
        border:none;
        cursor: pointer;
        box-Shadow: 2px 2px 4px rgba(0,0,0,0.3);
    }
    .smFB{
        background: url(/static/media/facebook.20566f7b.svg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
    .smIn{
        background: url(/static/media/instagram.7ee89ee5.svg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
    .smLi{
        background: url(/static/media/linkedin.cffcb16f.svg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
}
@media only screen and (min-width: 768px) {
    .footertext-bottom {
        display: flex;
        justify-content: center;
        align-items: center;
        color: rgba(255, 255, 255, 1);
        font-weight: light;
        font-size: 0.7vw;
    }
}


@media only screen and (max-width: 768px) {

    .footertext-bottom {
        height: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        flex-wrap: nowrap;
        color: rgba(255, 255, 255, 1);
        font-weight: light;
        font-size: 0.1em;
        
    }
    
}
@media only screen and (min-width: 768px) {
.home-bg{
    background: 
    linear-gradient(
      rgba(21, 43, 141, 0.4), 
      rgba(21, 43, 141, 0.4)),
      url(/static/media/home.97397501.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 5% 10% 3% 5%;
  }
  
  .titleboxhome {
    width: 70%;
    height: 15%;
    min-height: 100px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 3%;
    margin-right: 30%;
    background-color: rgba(255, 120, 0, 0.9);
    border-radius: 15px 15px 0px 15px;
    box-shadow: -2px -2px 10px 0px rgba(0,0,0,0.5);
  }
  .logoHome{
    width: 25vw;
    -webkit-filter: drop-shadow(2px 2px 2px rgba(0,0,0,0.4));
            filter: drop-shadow(2px 2px 2px rgba(0,0,0,0.4));
  }
  
  .home {
    width: 75%;
    background-color: rgba(21, 43, 141, 0.8);
    border-radius: 0px 15px 15px 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: rgba(255, 255, 255, 1);
    margin: 0% 0% 15% 0%;
    padding: 2% 3% 2% 3%;
    box-shadow: 2px 2px 6px rgba(0,0,0,0.7);
    /* filter: drop-shadow(2px 2px 4px rgba(0,0,0,0.9)); */
    
  }
  
  .title-home {
    padding: 2% 0% 1% 0%;
    font-weight: bold;
    align-self: flex-start;
  }

  .home-teaserbox{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    padding: 3% 0% 0% 0%;
   
  }

  .home-teaserItem{
    width: 20%;
    min-height: 100px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: nowrap;
    background-color: rgba(255, 120, 0, 0.9);
    box-shadow: 2px 2px 6px rgba(0,0,0,0.5);
    border-radius: 4px; 
    border: none;
    color: white;
    padding: 0;
    text-decoration: none;
    margin-right: 2%;
  }

  .home-teaserItem:hover{
    border: none;
    cursor: pointer;
  }

  .home-teaserItemImagebox{
    width: 100%;
    border-radius: 6px 6px 0px 0px;
  }

  .home-teaserbox-content{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 10px;
  }

  .home-ig-posts-box {
    width: 75%;
    height: 100%;
    padding: 1%;
    margin-top: 1%;
    /* border-radius: 15px 15px 15px 15px;
    background-color: rgba(21, 43, 141, 0.8);
    box-shadow: 2px 2px 6px rgba(0,0,0,0.7); */
    display: flex;
    flex-direction: column;
  }

  .home-titel-ig {
    align-self: center;
  }
  
}

@media only screen and (max-width: 768px) {

  .home-bg{
    background: linear-gradient( rgba(21, 43, 141, 1) 40%, rgba(21, 43, 141, 0.7)), url(/static/media/home3.f2199dea.jpg);
        background-size: cover;
        background-position: 0% 45%;
        background-attachment: fixed;
        background-repeat: no-repeat;
    padding: 20% 0px 0px 0px;
  }



  .titleboxhome {
    width: 100%;
    height: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3%;
   
   
    /* background-color: rgba(255, 120, 0, 1); */
    /* border-radius: 15px 15px 0px 0px; */
    /* box-shadow: -2px -2px 10px 0px rgba(0,0,0,0.5); */
    
  }
  .logoHome{
    width: auto;
    height: 100%;
    -webkit-filter: drop-shadow(2px 2px 2px rgba(0,0,0,0.3));
            filter: drop-shadow(2px 2px 2px rgba(0,0,0,0.3));
  }
  
  .home {
    width: 100%;
    background-color: transparant;
    border-radius: 0px 0px 15px 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    color: rgba(255, 255, 255, 1);
    margin: 0% 0% 0% 0%;
    padding: 1% 5% 10% 5%;
    /* box-shadow: 2px 2px 6px rgba(0,0,0,0.7); */
    
  }
  
  .title-home {
    width: 100%;
    padding: 4% 1% 4% 5%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 120, 0, 1);
    margin: 2% 0% 2% 0%;
    text-shadow: 2px 2px 6px rgba(0,0,0,0.7);
    font-size: 20px;
  }

  .home-titel-ig {
    text-align: center;
  }


 








}
@media only screen and (min-width: 768px) {
    .inspiratie-bg {
        background: 
        linear-gradient(
          rgba(255, 120, 0, 0.7), 
          rgba(255, 120, 0, 0.7)),
        url(/static/media/voetbal.09e0b643.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 0% 45%;
        background-attachment: fixed;
        padding: 0%;
      }

    .insp-container {
      width: 100%;  
      padding:5% 15% 5% 15%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
    }

    .insp-contentWindow{
      width: 100%;
      height: 100%;
      display:flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      flex-wrap: nowrap;
      -webkit-filter: drop-shadow(4px 4px 4px rgba(0,0,0,0.4));
              filter: drop-shadow(4px 4px 4px rgba(0,0,0,0.4));
    }

    .inspContent{
      width: 100%;  
      /* height: 100%; */
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap:wrap;
      background-color: rgba(21, 43, 141, 0.8);
      padding:2% 5% 2% 5%;
      border-radius:  5px 5px 5px 5px;
    }

    .insp-titel-bew {
      width: 100%;
      display: flex;
      text-transform: uppercase;
      justify-content: center;
      background-color: rgba(255, 120, 0, 0.7);
      padding-top: 1%;
      margin-top: 2%;
      border-radius: 5px 5px 0px 0px;
    }

    .insp-titel-stil {
      width: 100%;
      display: flex;
      text-transform: uppercase;
      justify-content: center;
      background-color: rgba(21, 43, 141, 0.8);
      padding-top: 1%;
      margin-top: 2%;
      border-radius: 5px 5px 0px 0px;
    }

    .insp-bg-oranje {
      width: 100%;
      background-color: rgba(255, 120, 0, 0.7);
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      border-radius: 0px 0px 5px 5px;
    }

    .insp-bg-blauw {
      width: 100%;
      background-color: rgba(21, 43, 141, 0.8);
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      border-radius: 0px 0px 5px 5px;
    }
    .inspVideoWrapper{
      width: 100%;  
      /* height: 100%; */
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      flex-wrap:wrap;
      
    }
    .insp-video-item{
      height:auto;
      max-height: 320px;
      max-width: 320px;
      margin: 20px;
      -webkit-filter: drop-shadow(4px 4px 4px rgba(0,0,0,0.6));
              filter: drop-shadow(4px 4px 4px rgba(0,0,0,0.6));
    }

    .inspStillWrapper{
      width: 100%;  
      /* height: 100%; */
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      flex-wrap:wrap;
      padding:0%;
    }

    .insp-still-item{
        width: 220px;
        height: auto;
        border-radius: 5px;
        margin: 20px;
        -webkit-filter: drop-shadow(4px 4px 4px rgba(0,0,0,0.6));
                filter: drop-shadow(4px 4px 4px rgba(0,0,0,0.6));
    }
}

@media only screen and (max-width: 768px) {
  .inspiratie-bg {
    background-color: rgba(21, 43, 141, 0.8);
    padding: 0%;
  }

.insp-contentWindow{
  width: 100%;
  height: 100%;
  padding:1% 5% 1% 5%;
  display:flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
}

.inspContent{
  width: 100%;  
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap:wrap;
  padding:5% 1% 5% 1%;
}
.insp-titel-bew {
  width: 100%;
  display: flex;
  text-transform: uppercase;
  justify-content: center;
  background-color: rgba(255, 120, 0, 0.7);
  padding-top: 2%;
  margin-top: 2%;
  border-radius: 5px 5px 0px 0px;
}

.insp-titel-stil {
  width: 100%;
  display: flex;
  text-transform: uppercase;
  justify-content: center;
  background-color: rgba(21, 43, 141, 0.8);
  padding-top: 2%;
  margin-top: 2%;
  border-radius: 5px 5px 0px 0px;
}

.insp-bg-oranje {
  width: 100%;
  background-color: rgba(255, 120, 0, 0.7);
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  border-radius: 0px 0px 5px 5px;
}

.insp-bg-blauw {
  width: 100%;
  background-color: rgba(21, 43, 141, 0.8);
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  border-radius: 0px 0px 5px 5px;
}
.inspVideoWrapper{
  width: 100%;  
  /* height: 100%; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  flex-wrap:wrap;
  padding: 5%;
  
}
.insp-video-item{
    height:auto;
    max-height: 320px;
    max-width: 320px;
    margin: 20px;
    -webkit-filter: drop-shadow(4px 4px 4px rgba(0,0,0,0.6));
            filter: drop-shadow(4px 4px 4px rgba(0,0,0,0.6));
}

.inspStillWrapper{
  width: 100%;  
  /* height: 100%; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap:wrap;
  padding:0%;
}

.insp-still-item{
    width: 240px;
    height: auto;
    background-color: rgb(0,0,0);
    border-radius: 5px;
    margin: 20px;
    -webkit-filter: drop-shadow(4px 4px 4px rgba(0,0,0,0.6));
            filter: drop-shadow(4px 4px 4px rgba(0,0,0,0.6));
}
    
}
.iiz {
    max-width: 100%;
    margin: 0;
    position: relative;
    overflow: hidden;
    display: inline-block;
    cursor: zoom-in;
  }
  
  .iiz--drag .iiz__zoom-img--visible {
    cursor: grab;
  }
  
  .iiz__img {
    max-width: 100%;
    height: auto;
    display: block;
    pointer-events: none;
    visibility: visible;
    opacity: 1;
  }
  
  .iiz__img--hidden {
    visibility: hidden;
    opacity: 0;
  }
  
  .iiz__img--abs {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: block;
  }
  
  .iiz__zoom-img {
    width: auto !important;
    max-width: none !important;
    position: absolute;
    visibility: hidden;
    opacity: 0;
    display: block;
  }
  
  .iiz__zoom-img--visible {
    visibility: visible;
    opacity: 1;
    cursor: zoom-out;
  }
  
  .iiz__zoom-portal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10000;
  }
  
  .iiz__btn {
    background: rgba(255, 255, 255, 0.8);
    width: 30px;
    height: 30px;
    border: none;
    outline: none;
    padding: 0;
    position: absolute;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 4px;
  }
  
  .iiz__btn:before {
    content: " ";
    background-position: center;
    background-repeat: no-repeat;
    display: block;
  }
  
  .iiz__hint {
    bottom: 10px;
    right: 10px;
    pointer-events: none;
  }
  
  .iiz__hint:before {
    content: " ";
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 19.9 19.9'%3E%3Cpath d='M13.9 7.4C13.9 3.8 11 .9 7.4.9S.9 3.8.9 7.4s2.9 6.5 6.5 6.5 6.5-2.9 6.5-6.5zm5.3 12.5l-6.7-7.2c-1.4 1.3-3.2 2.1-5.1 2.1-4.1 0-7.4-3.3-7.4-7.4S3.3 0 7.4 0s7.4 3.3 7.4 7.4c0 1.7-.6 3.4-1.7 4.7l6.8 7.2-.7.6z' fill='%23000222'/%3E%3C/svg%3E");
    width: 20px;
    height: 20px;
  }
  
  .iiz__close {
    top: 10px;
    right: 10px;
    visibility: hidden;
    opacity: 0;
  }
  
  .iiz__close--visible {
    visibility: visible;
    opacity: 1;
  }
  
  .iiz__close::before {
    content: " ";
    width: 29px;
    height: 29px;
    background-image: linear-gradient(#222, #222), linear-gradient(#222, #222);
    background-size: 100% 1px, 1px 100%;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
@media only screen and (min-width: 768px) {

    .portfolio-bg {
        padding: 0px;
    }

    .portfolioWrapper{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        flex-wrap: nowrap;
    }

    .portfolioHeaderInput{
        width: 100%;
        height: 5%;
        display: flex;
        justify-content: center;
        align-items: center;
        /* border-top: 1px solid rgba(177,177,155,0.8); */
        /* border-top: 1px solid rgba(21, 43, 141, 0.9);
        border-bottom: 1px solid rgba(21, 43, 141, 0.9); */
        padding: 10px 5% 10px 10%;
        background-color: rgba(255, 120, 0, 1);
        /* background-color:rgba(21, 43, 141, 0.1); */
    }

    
    .mapWrapper{
        width: 100%;
        height: 95%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        /* border-top: 2px solid rgba(177,177,155,0.8); */
        
    }

    /* postcode component------------------------ */
    .pc-input-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        flex-wrap: nowrap;
        font-size: 0.8em;
        color:rgba(21, 43, 141, 1);
        /* color:rgba(254, 254, 254, 0.9); */
        
    }

    .pc-inputBoxTxt {
        width: 8%;
        height: 100%;
        padding:1% 0% 1% 2%;
        border-radius: 25px;
        background-color: rgb(230,230,230);
        border: 1px solid rgb(150,150,150);
        margin:0px 20px 0px 10px;
        outline: none;
    }
    .pc-inputSlider{
        width: 25%;
        margin: 0px 10px 0px 20px;
    }

    .pc-distance{
        width: 10%;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 0.9em;
        /* border-radius: 8px; */
        font-weight: 800;
        /* color: rgba(21, 43, 141, 0.8); */
        /* background-color:  rgba(21, 43, 141, 0.3); */
        margin:0px 5% 0px 0px;
        padding: 1%;
    }

    .pc-inputBtn {
        width: auto;
        height:100%;
        background-color:rgba(21, 43, 141, 1);
        color: white;
        display: flex;
        justify-content: center;
        align-items:center;
        cursor: pointer;
        outline: none;
        border:1px solid rgba(21, 43, 141, 1);
        border-radius: 4px;
        padding: 1% 2% 1% 2%;
        -webkit-filter: drop-shadow(1px 1px 1px rgba(0,0,0,0.5));
                filter: drop-shadow(1px 1px 1px rgba(0,0,0,0.5));
    }

    .pc-inputBtn:disabled  {
        background-color:rgba(21, 43, 141, 0.1);
        color: rgba(21, 43, 141, 0.2);
        border:1px solid rgba(21, 43, 141, 0.1);
       
    }
    .pc-message{
        width: auto;
        height:100%;
        display: flex;
        justify-content: flex-start;
        align-items:center;
        font-weight: 700;
        padding: 10px;
        background-color:rgba(255, 120, 0, 0.3);
        margin:0px 0% 0px 20px;
        padding: 1% 2% 1% 2%;
    }
    
    
    /* layout infowindow gogle maps */
    .gm-style-iw{
        max-width: 300px !important; 
        overflow: hidden !important;
    } 

    .loaderHolder{
        width: 40px;
        height: 40px;
        padding: 4px;
        margin-right: 10px;
    }
  
}
@media only screen and (max-width: 768px) {
    .portfolio-bg {
        padding: 0px;
        padding: 0% !important;
    }

    .portfolioWrapper{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        flex-wrap: nowrap;
    }

    .portfolioHeaderInput{
        width: 100%;
        height: 20%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid rgba(255, 120, 0, 1); 
        padding: 10px 10px 10px 10px; 
        background-color: rgba(255, 120, 0, 0.3);
    }

    
    .mapWrapper{
        width: 100%;
        height: 80%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        /* border-top: 2px solid rgba(177,177,155,0.8); */
        
    }

    /* postcode component------------------------ */
    .pc-input-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: row;
        flex-wrap: wrap;
        font-size: 0.7em;
        color:rgba(21, 43, 141, 1);
        
    }
    .pc-input-rows{
        width: 100%;
        height: 33%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: row;
        flex-wrap: wrap;
        font-family: 'FuturaStd-Book';
        font-size: 0.9em;
        color:rgba(21, 43, 141, 1);
    }

    .pc-inputBoxTxt {
        width: 40%;
        height:100%;
        padding:1% 0% 1% 2%;
        font-size: 1.2em;
        font-weight: 500;
        border-radius: 6px;
        background-color: rgb(230,230,230);
        border: 1px solid rgb(150,150,150);
        margin:0px 10px 0px 10px;
        outline: none;
    }

    .pc-sliderHolder{
        width: 100%;
        height: 10%;
        position:absolute;
        left:0px;
        bottom: 5%;  /* zelde waarde als footor height for mobile */
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        flex-wrap: nowrap;
        font-size: 0.8em;
        color:rgba(21, 43, 141, 1);
        z-index: 1000;
        background-color: rgba(255, 120, 0, 0.8);
        padding:  8px 10px 0px 10px;
    }
    .pc-inputSlider{
        width: 75%;
        margin-left: 10px;
    }

    .pc-distance{
        font-size: 0.9em;
        font-weight: 700;
        color: rgba(21, 43, 141, 1);
        margin:4px 0px 4px 0px;
        padding: 1%;
    }

    .pc-inputBtn {
        width: 20%;
        height:100%;
        background-color:rgba(21, 43, 141, 8);
        color: white;
        display: flex;
        justify-content: center;
        align-items:center;
        flex-wrap: wrap;
        cursor: pointer;
        outline: none;
        border: none;
        /* border:1px solid rgba(21, 43, 141, 0); */
        border-radius: 4px;
        /* padding: 1% 2% 1% 2%; */
        /* filter: drop-shadow(1px 1px 1px rgba(0,0,0,0.5)); */
    }

    .pc-inputBtn:disabled  {
        background-color:rgba(21, 43, 141, 0.1);
        color: rgba(21, 43, 141, 0.2)
       
    }
    .pc-message{
        width: 79%;
        height:100%;
        display: flex;
        justify-content: flex-start;
        align-items:center;
        font-weight: 700;
        padding: 10px;
        background-color:rgba(255, 120, 0, 0.2);
        margin:0px 1% 0px 0px;
        padding: 1% 2% 1% 2%;
    }
    

    /* layout infowindow gogle maps */
    .gm-style-iw{
        max-width: 300px !important; 
        overflow: hidden !important;
    } 
    
    .loaderHolder{
        width: 40px;
        height: 40px;
        padding: 4px;
        margin-right: 10px;
    }
    
}
@media only screen and (min-width: 768px) {
    .vs-root{
        width: 100%;
        display:flex;
        flex-direction: column;
        justify-content:flex-start;
        margin-bottom: 10%;
        background-color: rgba(254,254,254,0.2);
        padding: 2% 2% 2% 2%;
        border-radius: 6px;
        box-shadow: 2px 2px 2px rgba(0,0,0,0.5);
    }

    .vs-containervestiging{
        display:flex;
        flex-direction:row;
        width:100%;
    }
    .vs-employees-wrapper{
        width:100%;
        display:flex;
        flex-direction:row;
        flex-wrap: wrap;
        
    }

    .vs-employeeContainer {
        width:80px; 
        padding: 2% 2% 2% 2%;
        display:flex;
        flex-direction: column;
        justify-content:center;
        font-size:0.8em;
        
    }

    .vs-employeeIcon {
        width: 100%;
        padding: 12% 12% 8% 12%;
        margin-bottom: 1%;
        -webkit-filter: drop-shadow(2px 2px 4px rgba(0,0,0,0.4));
                filter: drop-shadow(2px 2px 4px rgba(0,0,0,0.4));
    }

    .vs-infoContainer {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .vs-top-company-wrapper{
        width: 100%;
        display:flex;
        flex-direction: row;
        justify-content:flex-start;
        flex-wrap: wrap;
    }
    .vs-pand{
        width: 30%;
        margin: 0px 20px 20px 0px;
        border-radius: 10px;
        box-shadow: 2px 2px 2px rgba(0,0,0,0.5);
    }
}
@media only screen and (max-width: 768px) {
    .vs-root{
        width: 100%;
        display:flex;
        flex-direction: column;
        justify-content:flex-start;
        margin-bottom: 10%;
        background-color: rgba(254,254,254,0.2);
        padding: 2% 2% 2% 2%;
        border-radius: 6px;
        box-shadow: 2px 2px 2px rgba(0,0,0,0.5);
    }

    .vs-containervestiging{
        display:flex;
        flex-direction:row;
        width:100%;
    }
    .vs-employees-wrapper{
        width:100%;
        display:flex;
        flex-direction:row;
        flex-wrap: wrap;
        
    }

    .vs-employeeContainer {
        width:60px; 
        padding: 2% 2% 2% 2%;
        display:flex;
        flex-direction: column;
        justify-content:center;
        font-size:0.8em;
        
    }

    .vs-employeeIcon {
        width: 100%;
        padding: 12% 12% 8% 12%;
        margin-bottom: 1%;
        -webkit-filter: drop-shadow(2px 2px 4px rgba(0,0,0,0.4));
                filter: drop-shadow(2px 2px 4px rgba(0,0,0,0.4));
    }

    .vs-infoContainer {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .vs-top-company-wrapper{
        width: 100%;
        display:flex;
        flex-direction: row;
        justify-content:flex-start;
        flex-wrap: wrap;
    }
    .vs-pand{
        width: 100%;
        margin: 0px 0px 20px 0px;
        border-radius: 6px;
        /* box-shadow: 2px 2px 2px rgba(0,0,0,0.5); */
    }
}
@media only screen and (min-width: 768px) {
    .overons-bg {
        padding: 0%;
        overflow-y: hidden;
      }

      .overOns-navi{
        width: 100%;
        height: 5%;  
        padding:0% 0% 0% 5%;
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
        flex-direction: row;
        flex-wrap: wrap;
        background-color: rgba(45, 61, 129, 0.8);
      }
      .overOns-mainContainer{
        width: 100%;
        height: 95%;
        margin: 0px;
        overflow-y: auto;
        overflow-x: hidden;
        display: flex;
        justify-content: center;
        align-items: flex-start;
      }

      .overOns-blocks-bg{
        width: 100%;  
        padding:5% 1% 5% 15%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
      }

      .overOns-blocks-bg-1{
        width: 100%;  
        padding:1% 1% 1% 1%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
      }

      .oo-bg-filosofie{
        background: linear-gradient(  rgba(255, 120, 0, 0.6),   rgba(255, 120, 0, 0.1)),  url(/static/media/teaser1.fe29b5c4.jpg);
        background-size: cover;
        background-position: 0% 45%;
        background-attachment: fixed;
        background-repeat: no-repeat;
      }

      .oo-bg-ooh{
        background: linear-gradient(  rgba(255, 120, 0, 0.8),  rgba(255, 120, 0, 0.1)),  url(/static/media/inspiratie.0e092ce9.jpg);
        background-size: cover;
        background-position: 0% 45%;
        background-attachment: fixed;
        background-repeat: no-repeat;
      }

      .oo-bg-3{
        background: linear-gradient( rgba(255, 120, 0, 0.6), rgba(255, 120, 0, 0.1)), url(/static/media/filosofie.ebaf2ac5.jpg);
        background-size: cover;
        background-position: 0% 45%;
        background-attachment: fixed;
        background-repeat: no-repeat;
      }

      .oo-bg-4{
        background: linear-gradient( rgba(255, 120, 0, 0.6),  rgba(255, 120, 0, 0.6)),  url(/static/media/useCases.b99817db.jpg);
        background-size: cover;
        background-position: 0% 45%;
        background-attachment: fixed;
        background-repeat: no-repeat;
      }

      .overOns-content-wrap{
        width: 60%;
        max-width: 60%;  
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap:nowrap;
        padding:5% 3% 5% 3%;
        background-color: rgba(21, 43, 141, 0.85);  
      }

      .scroll-y{
        overflow-y: auto;
      }

      .overOns-content-bottom{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap:wrap;
        margin-bottom: 10%;
      }


      .overOns-content-wrap-top{
        width: 100%;  
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        flex-wrap:wrap;
        background-color: rgba(250,250,250,0.95);
        padding:2% 2% 2% 2%;
        border-radius: 10px 10px 10px 10px;
        -webkit-filter: drop-shadow(4px 4px 4px rgba(0,0,0,0.4));
                filter: drop-shadow(4px 4px 4px rgba(0,0,0,0.4));
      }

      .overOns-content-wrap-1{
        width: 100%;  
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap:wrap;
        background-color: rgba(250,250,250,0.95);
        /* padding:2% 2% 2% 2%; */
        margin: 2% 0% 2% 0%;
        border-radius: 10px 10px 10px 10px;
        -webkit-filter: drop-shadow(4px 4px 4px rgba(0,0,0,0.4));
                filter: drop-shadow(4px 4px 4px rgba(0,0,0,0.4));
        
      }

      .overOns-nav {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
      }
  
      .overOns-text-holder{
        width: 80%;  
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: nowrap;
        padding: 0% 0% 0% 5%;
      }
      
      .navigation-OO {
        font-size: 0.8em;
        font-weight: 400;
        text-transform: uppercase;
        text-decoration: none;
      }
      
      .navigation-OO:hover {
        color: white;
      }

      .button-oo {
        background-color: rgba(15, 31, 100, 0.8);
        padding: 10px;
        border-radius: 5px;
      }

      .cont-top-vest-1 {
        width: 100%;
        height: 20%;
        display: flex;
        flex-direction: column;
      }
      
      .adresbox-OO {
        display: flex;
        flex-direction: column;
        padding: 0% 0% 0% 0%;
      }
}
@media only screen and (max-width: 768px) {

  .overons-bg {
    padding: 0% !important;
    overflow-y: hidden;
    background: linear-gradient( rgba(21, 43, 141, 1) 40%, rgba(21, 43, 141, 0.7)), url(/static/media/home3.f2199dea.jpg);
    background-size: cover;
    background-position: 0% 45%;
    background-attachment: fixed;
    background-repeat: no-repeat;
  }

  .overOns-navi{
    width: 100%;
    height: 5%;  
    padding:0% 0% 0% 0%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    background-color: rgba(45, 61, 129, 0.8);
  }
  .overOns-mainContainer{
    width: 100%;
    height: 95%;
    margin: 0px;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  .overOns-blocks-bg{
    width: 100%;  
    padding:5% 1% 5% 15%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .overOns-blocks-bg-1{
    width: 100%;  
    padding:1% 1% 1% 1%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
  }

  .overOns-content-wrap{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap:nowrap;
    padding:10% 5% 10% 5%;
    /* background-color: rgba(21, 43, 141, 0.85); */
    background-color: transparent;    
  }

  .scroll-y{
    overflow-y: auto;
  }

  .overOns-content-bottom{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap:wrap;
    margin-bottom: 10%;
  }


  .overOns-content-wrap-top{
    width: 100%;  
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    flex-wrap:wrap;
    background-color: rgba(250,250,250,0.95);
    padding:2% 2% 2% 2%;
    border-radius: 10px 10px 10px 10px;
    -webkit-filter: drop-shadow(4px 4px 4px rgba(0,0,0,0.4));
            filter: drop-shadow(4px 4px 4px rgba(0,0,0,0.4));
  }

  .overOns-content-wrap-1{
    width: 100%;  
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap:wrap;
    background-color: rgba(250,250,250,0.95);
    /* padding:2% 2% 2% 2%; */
    margin: 2% 0% 2% 0%;
    border-radius: 10px 10px 10px 10px;
    -webkit-filter: drop-shadow(4px 4px 4px rgba(0,0,0,0.4));
            filter: drop-shadow(4px 4px 4px rgba(0,0,0,0.4));
    
  }

  .overOns-nav {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  .overOns-text-holder{
    width: 80%;  
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: nowrap;
    padding: 0% 0% 0% 5%;
  }
  
  .navigation-OO {
    font-size: 0.8em;
    font-weight: 400;
    text-transform: uppercase;
    text-decoration: none;
  }
  
  .navigation-OO:hover {
    color: rgba(255, 120, 0, 1)
  }

  .cont-top-vest {
    width: 100%;
    height: 20%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  
  .adresbox-OO {
    display: flex;
    flex-direction: column;
    padding: 0% 0% 0% 0%;
  } 
}
@media only screen and (min-width: 768px) {
    
    .useCases-bg {
      background: url(/static/media/useCases.b99817db.jpg);
      background-size: cover;
      background-position: 0% 45%;
      background-attachment: fixed;
      padding: 0%;
    }

    .case-bg{
      width: 100%;  
      padding:5% 15% 5% 15%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
    }

    .bg-orange{
      background-color: rgba(255, 120, 0, 0.8);
    }
    .bg-bleu{
      background-color: rgba(45, 61, 129, 0.8);
    }

    .useCaseContentWrapper{
      width: 100%;  
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap:wrap;
      background-color: rgba(250,250,250,0.95);
      padding:2% 2% 2% 2%;
      border-radius: 10px 10px 10px 10px;
      -webkit-filter: drop-shadow(4px 4px 4px rgba(0,0,0,0.4));
              filter: drop-shadow(4px 4px 4px rgba(0,0,0,0.4));
      
    }

    .useCaseImgHolder{
      width: 20%;  
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
    }

    .useCaseTxtHolder{
      width: 80%;  
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: nowrap;
      padding: 0% 0% 0% 5%;
    }

    .useCaseImg{
      width: 100%;  
      height: 100%;
      object-fit: contain;
    }

    .useCasePadding{
      padding: 0% 0% 3% 0%;
    }

    

   

    







      
      
     
      
}
@media only screen and (max-width: 768px) {
  .useCases-bg {
    background: 
    linear-gradient(
      rgba(255, 120, 0, 0.8), 
      rgba(255, 120, 0, 0.8)),
      url(/static/media/useCases.b99817db.jpg);
    background-size: cover;
    background-position: 0% 45%;
    background-attachment: fixed;
    padding: 0%;
  }

  .case-bg{
    width: 100%;  
    padding:0% ;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .bg-orange{
    background-color: rgba(255, 255, 255, 0.5);
  }
  .bg-bleu{
    background-color: rgba(255, 255, 255, 0.8);
  }

  .useCaseContentWrapper{
    width: 100%;  
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap:nowrap;
    padding: 5% 0% 0% 5%;
  }

  .useCaseImgHolder{
    width: 60%;  
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0% 0% 0% 0%;
  }

  .useCaseTxtHolder{
    width: 100%;  
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: nowrap;
    padding: 5% 2% 0% 0%;
  }

  .useCaseImg{
    width: 100%;  
    height: 100%;
    object-fit: contain;
    scale: 30%;
  }

  .useCasePadding{
    padding: 0% 0% 3% 0%;
  }


    
}
@media only screen and (min-width: 768px) {
    
    .tarief-bg {
      background: url(/static/media/useCases.b99817db.jpg);
      background-size: cover;
      background-position: 0% 45%;
      background-attachment: fixed;
      padding: 0%;
    }

    .case-bg{
      width: 100%;  
      padding:5% 15% 5% 15%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
    }

    .bg-orange{
      background-color: rgba(255, 120, 0, 0.8);
    }
    .bg-bleu{
      background-color: rgba(45, 61, 129, 0.8);
    }

     
      
}
@media only screen and (max-width: 768px) {
  .tarief-bg {
    background: 
    linear-gradient(
      rgba(255, 120, 0, 0.8), 
      rgba(255, 120, 0, 0.8)),
      url(/static/media/useCases.b99817db.jpg);
    background-size: cover;
    background-position: 0% 45%;
    background-attachment: fixed;
    padding: 0%;
  }

  .case-bg{
    width: 100%;  
    padding:0% ;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .bg-orange{
    background-color: rgba(255, 255, 255, 0.5);
  }
  .bg-bleu{
    background-color: rgba(255, 255, 255, 0.8);
  }

  .useCaseContentWrapper{
    width: 100%;  
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap:nowrap;
    padding: 5% 0% 0% 5%;
  }

  .useCaseImgHolder{
    width: 60%;  
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0% 0% 0% 0%;
  }

  .useCaseTxtHolder{
    width: 100%;  
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: nowrap;
    padding: 5% 2% 0% 0%;
  }

  .useCaseImg{
    width: 100%;  
    height: 100%;
    object-fit: contain;
    scale: 30%;
  }

  .useCasePadding{
    padding: 0% 0% 3% 0%;
  }


    
}
 .App {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    flex-wrap: nowrap;
    overflow:hidden
  }


@media only screen and (min-width: 768px) {
  .main-container {
    width: 100%;
    height: 90%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0px 0px 0px 0px;
    margin: 0px 0px 0px 0px;
  }
  .container-home{
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 5% 10% 3% 10%;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .container{
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0%;
    overflow-y: auto;
    overflow-x: hidden;
  }



  .footer{
    width: 100%;
    height: 5%;
    background-color: rgba(21, 43, 141, 1);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    border-top: 1px solid rgba(177,177,155,0.3);
    /* padding-top: 10px; */
}

  .contentWrapper{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .textsizing-standard {
    font-size: 1em;
    font-family: 'FuturaStd-Medium';
  }

  .h1 {
    font-size: 2em;
    font-family: 'FuturaStd-Bold';
  }

  .h1-home {
    font-size: 1.7em;
    font-family: 'FuturaStd-Bold';
  }

  .titel{
    font-family: 'FuturaStd-Book';
    font-size: 1.5em;
    text-transform: uppercase;
  }
  .plat{
    font-size: 1em;
    line-height: 1.5;
    font-family: 'FuturaStd-Medium';
  }

  .italic{
    font-style:italic;
  }

}

@media only screen and (max-width: 768px) {

  .App {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    flex-wrap: nowrap;
    overflow:hidden
  }

  .main-container {
    width: 100%;
    height: 90%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    flex-wrap: nowrap;
    padding: 0px 0px 0px 0px;
    margin: 0px;
  }


  .container-home{
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 10% 3% 3% 3%;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .container{
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 10% 3% 3% 3%;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .contentWrapper{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .footer{
    width: 100%;
    height: 5%;
    background-color: rgba(21, 43, 141, 1);
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    border-top: 1px solid rgba(177,177,155,0.3);
    padding-top: 10px;
}


.textsizing-standard {
  font-size: 1em;
  font-family: 'FuturaStd-Medium';
}

.h1 {
  font-size: 1.5em;
  font-family: 'FuturaStd-Bold';
}

.titel{
  font-family: 'FuturaStd-Book';
  font-size: 1.1em;
  text-transform: uppercase;
}
.plat{
  font-size: 0.9em;
  line-height: 1.5;
  font-family: 'FuturaStd-Medium';
}

.italic{
  font-style:italic;
}

}

.blauw {
  color:rgba(21, 43, 141, 1)
}

.oranje {
  color:rgba(255, 120, 0, 1)
}
.wit{
  color:rgba(254, 245,245, 1)
}

.link-color-white:link, .link-color-white:visited {
  font-family: 'FuturaStd-Light';
  color: white;
  text-decoration: none;
}

.link-color-white:hover {
  color:rgba(255, 120, 0, 1);
}

.link-color-orange:link, .link-color-orange:visited {
  color: rgba(255, 120, 0, 1);
  text-decoration: none;
}

.link-color-orange:hover {
  color: rgba(255, 255, 255, 1);
}

.flstart{
  justify-content: flex-start;
  align-items: flex-start;
}


@font-face {
    font-family: 'FuturaStd-Medium';
    src: url(/static/media/FuturaStd-Medium.a5dda204.woff) format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'FuturaStd-Bold';
    src: url(/static/media/FuturaStd-Bold.a2c963eb.woff) format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'FuturaStd-Book';
    src: url(/static/media/FuturaStd-Book.e4baf533.woff) format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'FuturaStd-Bold';
    src: url(/static/media/FuturaStd-Bold.a2c963eb.woff) format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'FuturaStd-Light';
    src: url(/static/media/FuturaStd-Light.af135833.woff) format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'FuturaStd-CondensedBold';
    src: url(/static/media/FuturaStd-CondensedBold.c3f533d6.woff) format('woff');
    font-weight: normal;
    font-style: normal;
}


html {
  box-sizing: border-box;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Firefox */ /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently
                                supported by Chrome and Opera */
}
*, *:before, *:after {
  box-sizing: inherit;
}


body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'FuturaStd-Medium';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: auto;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Firefox */ /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}




/* scrollbarr layout */
::-webkit-scrollbar {
  width:9px;
}

::-webkit-scrollbar-track {
  border-radius:5px;
  background:rgba(0,0,0,0.1);
  /* background:rgba(0,0,0,0.8); */
}

::-webkit-scrollbar-thumb {
  border-radius:5px;
  background:rgba(0,0,0,0.2);
}

::-webkit-scrollbar-thumb:hover {
  background:rgba(0,0,0,0.4);
}

::-webkit-scrollbar-thumb:window-inactive {
  background:rgba(0,0,0,0.05);
}


