@media only screen and (min-width: 768px) {
    /* social media */
    .smWrapper{
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .smButton{
        height: 30px;
        width: 30px;
        margin-left: 10px;
        outline: none !important;
        border:none;
        cursor: pointer;
        box-Shadow: 2px 2px 4px rgba(0,0,0,0.3);
    }
    .smFB{
        background: url('./facebook.svg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
    .smIn{
        background: url('./instagram.svg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        margin-right: 15px;
    }
    .smLi{
        background: url('./linkedin.svg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }


}
@media only screen and (max-width: 768px) {
    /* social media */
    .smWrapper{
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20% 0% 0% 0%;
    }

    .smButton{
        height: 40px;
        width: 40px;
        margin-left: 20px;
        outline: none !important;
        border:none;
        cursor: pointer;
        box-Shadow: 2px 2px 4px rgba(0,0,0,0.3);
    }
    .smFB{
        background: url('./facebook.svg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
    .smIn{
        background: url('./instagram.svg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
    .smLi{
        background: url('./linkedin.svg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
}