@media only screen and (min-width: 768px) {
.home-bg{
    background: 
    linear-gradient(
      rgba(21, 43, 141, 0.4), 
      rgba(21, 43, 141, 0.4)),
      url('../../image_bg/home.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 5% 10% 3% 5%;
  }
  
  .titleboxhome {
    width: 70%;
    height: 15%;
    min-height: 100px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 3%;
    margin-right: 30%;
    background-color: rgba(255, 120, 0, 0.9);
    border-radius: 15px 15px 0px 15px;
    box-shadow: -2px -2px 10px 0px rgba(0,0,0,0.5);
  }
  .logoHome{
    width: 25vw;
    filter: drop-shadow(2px 2px 2px rgba(0,0,0,0.4));
  }
  
  .home {
    width: 75%;
    background-color: rgba(21, 43, 141, 0.8);
    border-radius: 0px 15px 15px 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: rgba(255, 255, 255, 1);
    margin: 0% 0% 15% 0%;
    padding: 2% 3% 2% 3%;
    box-shadow: 2px 2px 6px rgba(0,0,0,0.7);
    /* filter: drop-shadow(2px 2px 4px rgba(0,0,0,0.9)); */
    
  }
  
  .title-home {
    padding: 2% 0% 1% 0%;
    font-weight: bold;
    align-self: flex-start;
  }

  .home-teaserbox{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    padding: 3% 0% 0% 0%;
   
  }

  .home-teaserItem{
    width: 20%;
    min-height: 100px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: nowrap;
    background-color: rgba(255, 120, 0, 0.9);
    box-shadow: 2px 2px 6px rgba(0,0,0,0.5);
    border-radius: 4px; 
    border: none;
    color: white;
    padding: 0;
    text-decoration: none;
    margin-right: 2%;
  }

  .home-teaserItem:hover{
    border: none;
    cursor: pointer;
  }

  .home-teaserItemImagebox{
    width: 100%;
    border-radius: 6px 6px 0px 0px;
  }

  .home-teaserbox-content{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 10px;
  }

  .home-ig-posts-box {
    width: 75%;
    height: 100%;
    padding: 1%;
    margin-top: 1%;
    /* border-radius: 15px 15px 15px 15px;
    background-color: rgba(21, 43, 141, 0.8);
    box-shadow: 2px 2px 6px rgba(0,0,0,0.7); */
    display: flex;
    flex-direction: column;
  }

  .home-titel-ig {
    align-self: center;
  }
  
}

@media only screen and (max-width: 768px) {

  .home-bg{
    background: linear-gradient( rgba(21, 43, 141, 1) 40%, rgba(21, 43, 141, 0.7)), url('../../image_bg/home3.jpg');
        background-size: cover;
        background-position: 0% 45%;
        background-attachment: fixed;
        background-repeat: no-repeat;
    padding: 20% 0px 0px 0px;
  }



  .titleboxhome {
    width: 100%;
    height: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3%;
   
   
    /* background-color: rgba(255, 120, 0, 1); */
    /* border-radius: 15px 15px 0px 0px; */
    /* box-shadow: -2px -2px 10px 0px rgba(0,0,0,0.5); */
    
  }
  .logoHome{
    width: auto;
    height: 100%;
    filter: drop-shadow(2px 2px 2px rgba(0,0,0,0.3));
  }
  
  .home {
    width: 100%;
    background-color: transparant;
    border-radius: 0px 0px 15px 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    color: rgba(255, 255, 255, 1);
    margin: 0% 0% 0% 0%;
    padding: 1% 5% 10% 5%;
    /* box-shadow: 2px 2px 6px rgba(0,0,0,0.7); */
    
  }
  
  .title-home {
    width: 100%;
    padding: 4% 1% 4% 5%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 120, 0, 1);
    margin: 2% 0% 2% 0%;
    text-shadow: 2px 2px 6px rgba(0,0,0,0.7);
    font-size: 20px;
  }

  .home-titel-ig {
    text-align: center;
  }


 








}