@import './font/font.css';

html {
  box-sizing: border-box;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently
                                supported by Chrome and Opera */
}
*, *:before, *:after {
  box-sizing: inherit;
}


body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'FuturaStd-Medium';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: auto;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}




/* scrollbarr layout */
::-webkit-scrollbar {
  width:9px;
}

::-webkit-scrollbar-track {
  -webkit-border-radius:5px;
  border-radius:5px;
  background:rgba(0,0,0,0.1);
  /* background:rgba(0,0,0,0.8); */
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius:5px;
  border-radius:5px;
  background:rgba(0,0,0,0.2);
}

::-webkit-scrollbar-thumb:hover {
  background:rgba(0,0,0,0.4);
}

::-webkit-scrollbar-thumb:window-inactive {
  background:rgba(0,0,0,0.05);
}

