@media only screen and (min-width: 768px) {
    .footertext-bottom {
        display: flex;
        justify-content: center;
        align-items: center;
        color: rgba(255, 255, 255, 1);
        font-weight: light;
        font-size: 0.7vw;
    }
}


@media only screen and (max-width: 768px) {

    .footertext-bottom {
        height: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        flex-wrap: nowrap;
        color: rgba(255, 255, 255, 1);
        font-weight: light;
        font-size: 0.1em;
        
    }
    
}